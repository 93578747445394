﻿// Gutter creation mixin
@mixin columns-gutter($size) {
	$size-half: ($size / 2 * 1px);

	&--gutter-#{$size} {
		margin-left:  ($size-half * -1);
		margin-right: ($size-half * -1);

		.column {
			padding-left:  ($size-half);
			padding-right: ($size-half);
		}
	}
}

// Columns
.columns {
	@include clearfix();
	overflow: hidden;

	// Columns gutter
	@include columns-gutter(2);
	@include columns-gutter(20);
	@include columns-gutter(30);
	@include columns-gutter(32);
	@include columns-gutter(40);
}

// Column
.column {
	float: left;
	clear: left;
	width: 100%;

	&.column--relative {
		position: relative;
	}
}

@mixin styles($amount) {
	width: (100% / $amount);

	&:nth-child(n + 1) {
		clear: none;
	}

	&:nth-child(#{$amount}n + 1) {
		clear: left;
	}
}

// Responsive columns creation mixin
@mixin responsive-columns($amount) {
	@include styles($amount);

	&-ml {

		@media #{$mobile-large} {
			@include styles($amount);
		}
	}

	&-ts {

		@media #{$tablet-small} {
			@include styles($amount);
		}
	}

	&-t {

		@media #{$tablet} {
			@include styles($amount);
		}
	}

	&-l {

		@media #{$laptop} {
			@include styles($amount);
		}
	}

	&-lm {

		@media #{$laptop-med} {
			@include styles($amount);
		}
	}

	&-ll {

		@media #{$laptop-large} {
			@include styles($amount);
		}
	}

	&-ds {

		@media #{$desktop-small} {
			@include styles($amount);
		}
	}

	&-d {

		@media #{$desktop} {
			@include styles($amount);
		}
	}

	&-dl {

		@media #{$desktop-large} {
			@include styles($amount);
		}
	}
}

// Dual
.column--dual {
	@include responsive-columns(2);
}

// Triple
.column--triple {
	@include responsive-columns(3);
}

// Quad
.column--quad {
	@include responsive-columns(4);
}

// Quin
.column--quin {
	@include responsive-columns(5);
}

// Sext
.column--sext {
	@include responsive-columns(6);
}

// Sept
.column--sept {
	@include responsive-columns(7);
}