﻿.contact-person {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.contact-person__image,
	.contact-person__text {
		width: 100%;

		@media #{$tablet-small} {
			width: 50%;
		}
	}

	img {
		max-width: 188px;
		border-radius: 50%;
	}

	+ .contact-person {
		margin-top: 48px;
	}
}