﻿.slideshow {
    box-sizing: border-box;
    padding: 0;
    height: 460px;
    overflow: hidden;

    @media #{$laptop} {
        @include responsive-container(1920, 850);
    }

    &.lSSlideOuter .lSPager.lSpg {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;

        @media #{$desktop} {
            bottom: 160px;
        }

        > li a {
            width: 12px;
            height: 12px;
            background-color: #fff;
            opacity: 0.6;
        }

        > li:hover a,
        > li.active a {
            opacity: 1;
        }
    }
}