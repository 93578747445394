﻿// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i');

// Icons
@font-face {
    font-family: 'icons';
    src: url('../fonts/icons.eot?rpvpz5');
    src: url('../fonts/icons.eot?rpvpz5#iefix') format('embedded-opentype'),
        url('../fonts/icons.ttf?rpvpz5') format('truetype'),
        url('../fonts/icons.woff?rpvpz5') format('woff'),
        url('../fonts/icons.svg?rpvpz5#icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

$icon-arrow-down: '\e900';
$icon-arrow-left: '\e901';
$icon-arrow-right: '\e902';
$icon-arrow-up: '\e903';
$icon-close: '\e904';
$icon-menu: '\e905';
$icon-quote: '\e906';
