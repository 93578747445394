﻿/*------------------------------------*\
	#Toggler
\*------------------------------------*/

.toggler {
    z-index: 101;
    position: absolute;
    padding-left: 40px;
    height: 40px;
    top: 20px;
    left: 20px;
    font-size: 14px;
    color: $color-primary;
    cursor: pointer;

    &:after {
        position: absolute;
        font-size: 18px;
        text-indent: 0;
        top: 12px;
        left: 12px;
        @include icon($icon-close); // close
    }

    @media #{$laptop} {
        display: none;
    }

    span {
        vertical-align: text-bottom;
        display: block;
        margin: 6px 0;
    }
}

.toggler--closed {

    &:after {
        font-size: 24px;
        top: 8px;
        left: 8px;
        @include icon($icon-menu); // menu
    }
}