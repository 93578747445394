﻿table {
    width: 100%;

    td {
        display: block;
        width: 100%;

        @media #{$tablet-small} {
            float: left;
            width: 50%;
        }
    }
}
