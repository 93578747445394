﻿.legal  {
	position: relative;
	list-style: none;
	width: 100%;
	clear: both;
	padding: 18px 0;
    border-top: 1px solid rgba($color-primary, 0.1);
	margin: 0 auto;
	overflow: hidden;
	font: 12px/16px $font-text;

	li {
		display: inline-block;
		margin: 0 8px;
	}

	a {
        color: $color-text;
	}
}

li.panorama-studios  {
	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}