﻿.header-image {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-size: cover;
    background-position: center;

    @media #{$laptop} {
        height: 270px;
    }

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.6;
        background: rgba(#000, 0.5);
    }

    h1 {
        color: #fff;
        text-shadow: 0px 0px 10px rgba(51, 51, 51, 0.8);
        margin: 30px 0;

        @media #{$laptop} {
            margin-top: 190px;
        }
    }
}