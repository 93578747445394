﻿.image {
	margin: 3px 0 30px;
}

.image--left,
.image--right {
	margin: 20px 0;

	@media #{$tablet-small} {
		width: 530px;
	}

	@media #{$laptop} {
		float: right;
		width: 100%;
	}

	@media #{$laptop-med} {
		width: 530px;
	}
}


.image--left {
	@media #{$laptop} {
		float: left;
	}
}

.image--frontpage {
	max-width: 530px;

	@media #{$laptop} {
		max-width: 100%;
	}
}

.images {
	list-style: none;
	padding: 0;
	overflow: hidden;
	margin: 0 0 20px;
	position: relative;

	@media #{$tablet-small} {
		margin: 0 20px 20px;
	}

	li {
		padding: 20px 0;

		@media #{$tablet-small} {
			box-sizing: border-box;
			float: left;
			width: 50%;
			padding: 20px;
		}

		@media #{$laptop-med} {
			width: (100% / 3);

			&:nth-of-type(2) {
				clear: left;

				+ li,
				+ li + li {
					//margin-top: calc(-((352 / 530 * 100%) / 3) - 13px);
					margin-top: calc(-22.13836% - 13px);
				}
			}
		}

		img {
			margin: 0;
		}
	}
}