﻿.languages {
    text-align: center;
    position: relative;
    color: $color-text;
    margin: 28px 0 0;
    z-index: 1;

    &:hover,
    &.language--open {

        dd ul {
            display: block;

            @media #{$laptop} {
                box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
            }
        }

        &:after {
            display: none;
        }
    }

    @media #{$laptop} {
        position: absolute;
        top: 128px;
        left: 50%;
        width: 200px;
        margin: 0 0 0 -100px;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 39px 100px 0 100px;
            border-color: #fff transparent transparent transparent;
            content: '';
        }

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 42px 100px 0 100px;
            border-color: #000 transparent transparent transparent;
            content: '';
            opacity: 0.1;
        }

        .headroom--pinned.headroom--not-top & {
            top: 80px;
        }
    }

    dt {
        position: relative;
        z-index: 2;
        display: none;
        width: 24px;
        padding: 5px 0;
        text-indent: -9999px;

        &:before {
            background: url(../../images/languages.png) no-repeat;
            width: 24px;
            height: 17px;
            position: absolute;
            top: 8px;
            left: 0;
            content: '';
            text-indent: 0;
        }

        &.de:before,
        &.de-DE:before {
            background-position: 0 -17px;
        }

        &.fr:before,
        &.fr-FR:before {
            background-position: 0 -34px;
        }

        &.de-CH:before {
            background-position: 0 -51px;
        }

        &.fr-CH:before {
            background-position: 0 -68px;
        }

        &.en:before,
        &.en-US:before,
        &.en-GB:before {
            background-position: 0 -85px;
        }

        @media #{$laptop} {
            display: inline-block;
        }
    }

    dd ul {
        list-style: none;
        width: 100%;
        padding-top: 40px;
        border-top: 1px solid $color-primary;

        @media #{$laptop} {
            background: #fff;
            border: none;
            margin-top: -40px;
        }

        li {
            a {
                display: inline-block;
                padding: 3px 0 3px 34px;
                position: relative;
                color: $color-primary;
                text-transform: capitalize;

                &:hover {
                    text-decoration: none;
                    color: $color-secondary;
                }

                @media #{$laptop} {
                    width: 24px;
                    padding: 3px 0;
                    text-indent: -9999px;
                }

                &:before {
                    background: url(../../images/languages.png) no-repeat;
                    width: 24px;
                    height: 17px;
                    position: absolute;
                    top: 8px;
                    left: 0;
                    content: '';
                    text-indent: 0;
                }
            }

            &:hover a {
                text-decoration: underline;
            }

            &.de a:before,
            &.de-DE a:before {
                background-position: 0 -17px;
            }

            &.fr a:before,
            &.fr-FR a:before {
                background-position: 0 -34px;
            }

            &.de-CH a:before {
                background-position: 0 -51px;
            }

            &.fr-CH a:before {
                background-position: 0 -68px;
            }

            &.en a:before,
            &.en-US a:before,
            &.en-GB a:before {
                background-position: 0 -85px;
            }
        }

        @media #{$laptop} {
            position: absolute;
            display: none;
        }
    }
}
