﻿.form {
	padding: 7px 0 0;
	margin: 0 0 41px;

	label,
	.form__label {
        display: none;
	}

	input[type=text],
	textarea,
	.form__radio-button-list {
		float: left;
		width: 100%;
	}

	.form__control {
		overflow: hidden;
		margin: 0 0 10px;
	}

	.form__text-area {
		overflow: hidden;
		margin: 0 0 12px;
	}

	.form__button-container {
		padding: 12px 0 0;
	}
}