﻿.products {
	position: relative;
	z-index: 5;
	display: flex;
	flex-wrap: wrap;

	&.active .product--collapsible,
	&:hover .product--collapsible {
		opacity: 0.3;
		transition: opacity 250ms ease-in-out;
	}

	.product {
		transition: opacity 250ms ease-in-out;

		&.product--collapsible {
			cursor: pointer;
		}

		&:hover {
			opacity: 1;
		}

		&.active {
			opacity: 1;

			figure:before {
				position: absolute;
				bottom: -18px;
				left: 50%;
				z-index: 20;
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 18px 18px 0 18px;
				border-color: #fff transparent transparent transparent;
				margin-left: -18px;
			}

			.content {
				display: block;

				.button {
					box-shadow: none;
				}
			}
		}

		> a {
			color: $color-text;

			&:hover {
				text-decoration: none;
			}
		}

		figure {
			position: relative;
			padding: 20px;

			&.no-image {
				&:before {
					@include responsive-container(1, 1);
					position: absolute;
					top: 30px;
					right: 0;
					bottom: 0;
					left: 0;
					content: '';
					background: url(../../images/flower-placeholder.png) no-repeat 50% 50%;
					background-size: 75% auto;
					opacity: 0.2;
				}
			}

			img {
				padding: 10px;

				@media #{$laptop} {
					padding: 20px;
				}
			}
		}
	}

	.content {
		cursor: pointer;
		box-sizing: border-box;
		position: absolute;
		top: 100%;
		z-index: 10;
		background: $color-primary;
		color: #fff;
		padding: 40px;
		display: none;
		width: 100vw;
		right: 50%;
		left: 50%;
		margin: 0 -50vw;

		.container {
			@media #{$laptop} {
				column-count: 2;
				column-gap: 40px;
			}
		}

		h2,
		h3 {
			color: #fff;
			padding: 0;

			&:after {
				content: none;
			}
		}
		/*strong {
            -webkit-column-break-before: always;
            break-before: column;
        }*/
	}
}
