﻿.logo {
	float: left;
	z-index: 1;
	position: absolute;
	top: 15px;
	left: 50%;
	transform: translateX(-50%);

	@media #{$laptop} {
		top: 0;
		width: 200px;
		padding: 20px 0;
		background: #fff;

		.headroom--pinned.headroom--not-top & {
			width: 41px;
			height: 80px;
		}
	}

	a {
		display: inline-block;
		text-indent: -9999px;
		background: url(../../images/heemskerk-flora-logo.svg) no-repeat;
		background-size: 100% auto;
		overflow: hidden;
		width: 66px;
		height: 50px;

		@media #{$laptop} {
			width: 105px;
			height: 80px;

			.headroom--pinned.headroom--not-top & {
				width: 40px;
				height: 41px;
				background-size: 70px auto;
				background-position: -15px 0;
			}
		}
	}
}

.footer-logo {
	box-sizing: border-box;
	height: 0;
	padding: 0 0 17.5%;
	background: url(../../images/heemskerk-flora-logo.svg) no-repeat;
	background-size: 100% auto;
	width: 100%;
	max-width: 105px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
	text-indent: -9999px;
}