﻿.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 460px;
    z-index: 1;
    background: #000;

    @media #{$laptop} {
        height: 100%;
    }

    &:first-of-type {
        z-index: 2;
    }

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.6;
        background: rgba(#000, 0.5);
    }

    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1040px;
        height: 460px;

        @media #{$laptop} {
            width: 100%;
            height: auto;
            position: static;
            left: auto;
            transform: none;
        }
    }

    figcaption {
        text-align: center;
        width: 100%;
        max-width: 420px;
        margin: 0 auto;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0; /* make things invisible upon start */
        animation: fadeInMargin ease-in 1;
        animation-fill-mode: forwards;
        animation-duration: 1s;
        animation-delay: 0;

        @media #{$laptop} {
            max-width: 680px;
            padding-top: 40px;
        }

        @media #{$laptop-large} {
            max-width: 420px;
            padding-top: 0;
        }

        h2 {
            color: #fff;
            text-shadow: 0px 0px 10px rgba(51, 51, 51, 0.8);
            padding: 0;


            @media #{$laptop-large} {
                font-size: 48px;
                line-height: 60px;
            }

            &:after {
                content: none;
                padding: 0;
            }
        }

        .button {
            margin: 0 20px;
        }
    }
}

@keyframes fadeInMargin {
    from {
        opacity: 0;
        margin-top: 10px;
    }

    to {
        opacity: 1;
        margin-top: 0;
    }
}