﻿input[type=text],
input[type=password],
input[type=email],
input[type=tel],
textarea,
select {
    box-sizing: border-box;
    font: 16px/36px $font-text;
    padding: 4px 16px 3px;
    -webkit-appearance: none;
    outline: none;
    height: 50px;
    border: none;
    border-radius: 4px;
    background: rgba($color-primary, 0.05);

    section:nth-of-type(even) & {
        background: #fff;
    }

    &.ivld {
        background: mix(red, #fff, 20%);
        border-color: mix(red, #fff, 40%);
    }

    &.required {
        border-left: 1px solid mix(red, #fff, 40%);
    }
}

input[type=file] {

	&.required {
		border-left: 2px solid mix(red, #fff, 40%);
	}
}

textarea {
	resize: vertical;
	overflow: auto;
	height: auto;
	min-height: 202px;
}

label {
	font: 16px/36px $font-text;
	padding: 4px 16px 3px;
}

select {
	-moz-appearance: none;
	border-radius: 0;
	padding: 8px 16px 9px;
}

select::-ms-expand {
	display: none;
}

.form-check {
	display: none;
}

