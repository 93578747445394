﻿@import "variables.scss";

@mixin responsive-container($width, $height) {
	position: relative;
	overflow: hidden;
	height: 0;
	padding: 0 0 (100% * $height / $width);
}

@mixin clearfix() {
	/*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}

	&:after {
		clear: both;
	}

	// For IE 6/7 only: include this rule to trigger hasLayout and contain floats.
	*zoom: 1;
}

@mixin full-width {
	position: absolute;
	width:  100%;
	height: 100%;
	top:  0;
	left: 0;
}

@mixin underline($height: 2px, $width: 80px, $background: linear-gradient(to right, $color-primary 0%,$color-secondary 100%)) {
	position: relative;
	padding-bottom: 20px;

	&:after {
		position: absolute;
		content: '';
		width: $width;
		height: $height;
		bottom: 0;
		left: 50%;
		margin-left: -($width / 2);
		background: $background;
	}
}

@mixin icon($content, $font-family: 'Icons') {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: $font-family;
	line-height: 1;
	text-transform: none;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: greyscale;
}