﻿/*------------------------------------*\
	#Route form
\*------------------------------------*/

.form--route.form {
	padding: 25px 0 0;
	margin: 0;

	@media #{$tablet} {
		display: flex;
		max-width: 640px;
		margin: 0 auto;
	}

	input[type=text] {
		width: 100% !important;
		margin-bottom: 20px;

		@media #{$tablet} {
			border: none;
			margin-bottom: auto;
			margin-right: 20px;
		}
	}
}

.form--route__button {
	width: 100%;

	 @media #{$tablet} {
		flex: 0 0 auto;
		width: auto;
	}
}