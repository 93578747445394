﻿.quote {
    letter-spacing: 0.05em;
    position: relative;
    z-index: 2;
    overflow: visible;
    padding-top: 125px;

    @media #{$laptop-med} {
        &:before,
        &:after {
            position: absolute;
            bottom: 0;
            content: '';
        }

        &:before {
            background: url(../../images/quote-left.png) no-repeat;
            width: 629px;
            height: 457px;
            left: 0;
            transform: translateX(-50%);
        }

        &:after {
            background: url(../../images/quote-right.png) no-repeat;
            width: 288px;
            height: 413px;
            right: 0;
            transform: translateX(50%);
        }
    }

    @media #{$desktop-small} {
        &:before,
        &:after {
            transform: translateX(0);
        }
    }

    .container {
        position: relative;
        z-index: 2;

        &:before {
            @include icon($icon-quote); // quote
            font-size: 64px;
            position: absolute;
            top: -90px;
            left: 50%;
            margin-left: -32px;
        }
    }

    h2 {
        padding-bottom: 0;
        font-style: italic;

        &:after {
            content: none;
        }
    }

    .full-quote {
        font-style: italic;
    }

    .company {
        &:before {
            content: " - ";
        }
    }
}
