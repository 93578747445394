﻿/*------------------------------------*\
	#Responsive nav
\*------------------------------------*/

.responsive-nav {
    box-sizing: border-box;
    position: fixed;
    z-index: 10;
    width: 300px;
    height: 100vh;
    padding-top: 80px;
    top: 0;
    left: -300px;
    will-change: transform;
    transition: all 0.3s ease-in-out;
    background: $color-contrast;
    overflow-x: hidden;
    overflow-y: visible;
    box-shadow: inset -5px 0px 10px 0px rgba(0,0,0,0.25);

    @media #{$laptop} {
        position: static;
        width: auto;
        height: auto;
        padding-top: 0;
        opacity: 1;
        background: none;
        overflow: visible;
        transition: none;
        box-shadow: none;

        &.collapsed {
            left: 0;
            transition: none;
        }
    }

    .headroom--unpinned & {
        top: 80px;
    }
}