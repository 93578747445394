﻿header {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 80px;
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
    z-index: 100;
    transition: left 0.3s ease-in-out;

    @media #{$laptop} {
        background: none;
        position: absolute;
        z-index: 20;
        height: 140px;
        box-shadow: none;

        &.headroom--pinned.headroom--not-top {
            position: fixed;
            top: 0;
            left: 0;
            background: #fff;
            width: 100%;
            height: 80px;
            box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
            transition: transform 0.3s ease-in-out;
        }
    }

    .open & {
        left: 300px;

        @media #{$laptop} {
            left: 0;
        }
    }
}


.headroom {
    will-change: transform;
    transition: all 0.3s ease-in-out;
}

.headroom--pinned {
    transform: translateY(0%);
}

.headroom--unpinned {
    transform: translateY(-100%);
}