﻿.employee {
    font-style: italic;

    span {
        font-style: normal;
        display: block;

        &.function {
            font-weight: 500;
        }

        &:last-of-type {
            margin-bottom: 30px;
        }
    }
}

.employees {
    span {
        display: block;

        &.mail {
            margin-bottom: 30px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    a {
        color: $color-secondary;

        &[href^=tel] {
            color: $color-text;
        }
    }
}