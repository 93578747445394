﻿.container {
	width: 100%;
	max-width: 1368px;
	padding: 0 20px;
	margin: 0 auto;
	@include clearfix();
}

.container--relative {
	position: relative;
    z-index: 2;
}

.container--small {
    max-width: 910px;
}