﻿footer {
    background: #fff;
    padding-top: 50px;
    position: relative;

    &:before {
        background: linear-gradient(to right, $color-primary 0%,$color-secondary 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 10px;
        content: '';
    }

    h2 {
        font-size: 24px;
        margin-bottom: 12px;
    }

    .columns--gutter-40 {
        margin: 0 0 20px;
        overflow: hidden;

        @media #{$laptop} {
            display: flex;
            margin: 0 -20px 50px;
        }

        .column {
            margin: 0 0 30px;

            @media #{$tablet-small} {
                margin: 0;
            }
        }

        .column + .column {
            padding-top: 20px;
            border-top: 1px solid rgba($color-primary, 0.1);

            @media #{$tablet-small} {
                padding-top: 0;
                border: none;
                margin: 0;
            }
        }

        .column--logo {
            display: none;

            + .column {
                clear: none;
            }

            @media #{$laptop} {
                display: flex;

                + .column {
                    clear: both;
                }
            }
        }
    }
}

.footer__container {
	position: relative;
}