﻿.button {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    display: inline-block;
    font: 16px/30px $font-text;
    color: $color-text;
    -webkit-appearance: none;
    cursor: pointer;
    text-decoration: none;
    padding: 10px 18px;
    height: 50px;
    border-radius: 4px;
    border: none;
    border-left: 2px solid $color-primary;
    border-right: 2px solid $color-secondary;
    box-sizing: border-box;
    background-color: transparent;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    background-image: linear-gradient(to right, $color-primary 0%, $color-secondary 100%), linear-gradient(to right, $color-primary 0%, $color-secondary 100%);
    transition: color 250ms ease-in-out;

    &:before {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        opacity: 0;
        background: linear-gradient(to right, $color-primary 0%, $color-secondary 100%), linear-gradient(to right, $color-primary 0%, $color-secondary 100%);
        transition: opacity 250ms ease-in-out;
    }

    &:hover {
        color: #fff;
        text-decoration: none;

        &:before {
            opacity: 1;
        }
    }
}

.button--white {
    border: 2px solid #fff;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(51,51,3,0.8);

    &:hover {
        background: #fff;
        color: $color-text;

        &:before {
            display: none;
        }
    }
}