﻿.nav {
    list-style: none;
    padding: 0;
    margin: 0;

    &.nav-left,
    &.nav-right {

        @media #{$laptop} {
            width: calc(50% - 100px);
            margin: 45px 0;

            .headroom--pinned.headroom--not-top & {
                margin: 16px 0;
            }
        }

        li {
            display: inline-block;
            width: 100%;
            position: relative;

            @media #{$laptop} {
                width: auto;

                &:hover {
                    background: #fff;

                    a {
                        color: $color-text;
                        text-shadow: none;
                    }

                    ul {
                        display: block;
                    }
                }
            }

            &.home {
                @media #{$laptop} {
                    display: none;
                }
            }

            &.sub {
                > a {
                    position: relative;
                    padding-right: 20px;

                    &:after {
                        @include icon($icon-arrow-down); // arrow down
                        font-size: 10px;
                        margin-left: 6px;
                        vertical-align: middle;

                        @media #{$laptop} {
                            display: none;
                        }
                    }
                }
            }

            a {
                color: $color-primary;
                display: block;
                padding: 10px 20px;
                font-size: 22px;

                &:hover {
                    color: $color-secondary;
                    text-decoration: none;
                    text-shadow: none;
                }

                @media #{$laptop} {
                    font-size: 18px;
                    color: #fff;
                    text-shadow: 0px 0px 10px rgba(51, 51, 51, 0.8);

                    .headroom--pinned.headroom--not-top & {
                        color: $color-text;
                        text-shadow: none;

                        &:hover {
                            color: $color-secondary;
                        }
                    }
                }
            }
            // Second level
            ul {
                margin: 0;

                @media #{$laptop} {
                    display: none;
                    position: absolute;
                    top: 47px;
                    left: 0;
                    background: #fff;
                    min-width: 100%;
                    color: $color-text;
                    box-shadow: 0px 20px 5px 0px rgba(0,0,0,0.25);

                    li {
                        margin: 0;

                        a {
                            text-shadow: none;
                            white-space: nowrap;

                            &:hover {
                                color: $color-primary;
                            }
                        }
                    }
                }

                li {
                    a {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    &.nav-left {
        @media #{$laptop} {
            float: left;
        }
    }

    &.nav-right {
        @media #{$laptop} {
            float: right;
        }
    }
}
