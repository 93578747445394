﻿.call-to-action {
	clear: both;
	position: relative;
	height: 260px;
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: center;

	@media #{$tablet} {
		height: 480px;
	}

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.6;
		background: rgba(#000, 0.5);
	}
}

.call-to-action__container {
	position: relative;
	z-index: 2;
	padding: 20px 0;
}

.call-to-action__title {
	font: 200 24px/1.25em $font-text;
	letter-spacing: 0.05em;
	color: #fff;
	text-shadow: 0px 0px 10px rgba(51, 51, 51, 0.8);
	margin-right: auto;
	margin-left: auto;

	@media #{$tablet-small} {
		font-size: 36px;
	}

	@media #{$laptop} {
		max-width: (100% / 3);
		font-size: 48px;
	}
}