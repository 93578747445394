﻿section {
	padding: 100px 0 70px;
	@include clearfix();

	&:nth-of-type(even) {
		background: rgba($color-primary, 0.05);
	}

	h2 {
		@include underline();
	}

	@media #{$desktop} {
		&.top--pull {
			background: none;
			margin-top: -140px;
			padding: 0 0 50px;
			position: relative;

			> .container--small {
				position: relative;
				z-index: 10;
				background: #fff;
				padding-top: 40px;
				padding-right: 249px;
				padding-left: 249px;
				max-width: 1368px;
			}

			section + & {
				margin-top: 0;
			}
		}
	}

	&.valign {
		@media #{$laptop} {
			padding: 50px 0;

			.columns {
				display: flex;
			}

			.column:first-child {
				position: relative;
			}

			.content {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}
